@font-face {
    font-family: 'Satoshi Bold';
    src: url('./fonts/Satoshi-Bold.woff2') format('woff2'),
    url('./fonts/Satoshi-Bold.woff') format('woff'),
    url('./fonts/Satoshi-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Satoshi Regular';
    src: url('./fonts/Satoshi-Regular.woff2') format('woff2'),
    url('./fonts/Satoshi-Regular.woff') format('woff'),
    url('./fonts/Satoshi-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.App {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: url('../public/background.jpg');
    background-size: cover;
    background-position: center;
}


.my-header {
    font-family: 'Satoshi-Bold', sans-serif;
    font-size: 3vw; /* Responsive font size using vw unit */
    color: white;
    text-align: left;
    position: absolute;
    top: 30%; /* Vertically center */
    left: 15%; /* Adjust left position as needed */
}

.my-text {
    font-family: 'Satoshi-Regular', sans-serif;
    font-size: 1.5vw; /* Responsive font size using vw unit */
    color: white;
    text-align: left;
    position: absolute;
    top: 45%; /* Vertically center */
    left: 15%; /* Adjust left position as needed */
    transform: translateY(-50%);
}

.my-footer {
    font-family: 'Satoshi-Regular', sans-serif;
    font-size: 1.5vw; /* Responsive font size using vw unit */
    color: white;
    text-align: left;
    position: absolute;
    bottom: 10px; /* Adjust bottom position as needed */
    left: 15%; /* Adjust left position as needed */
}


.Background {
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Logo {
    width: 8%; /* Responsive logo width */
    max-width: 200px;
    height: auto;
    position: absolute;
    top: 30px; /* Distance from top */
    left: 30px;
}

.Content {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 20px;
    border-radius: 10px;
    margin-top: 20px;
    max-width: 400px;
}

.Link {
    color: #61dafb;
    text-decoration: none;
}

.eit1 {
    max-width: 300px; /* Set a maximum width */
    height: auto;     /* Maintain aspect ratio */
    /*margin-left: 10px; !* Add some spacing between the logos *!*/
}

.eit2 {
    max-width: 300px; /* Set a maximum width */
    height: auto;     /* Maintain aspect ratio */
    margin-left: 50px; /* Add some spacing between the logos */
}
@media (max-width: 768px) {
    .Logo {
        width: 50%; /* Increase logo width for mobile */
        /*max-width: 150px;*/
        top: 10px; /* Adjust top position as needed */
        left: 10px; /* Adjust left position as needed */
    }
    .my-header {
        font-size: 6vw; /* Increase font size for mobile */
    }
    .eit1, .eit2 {
        max-width: 200px; /* Make the images smaller on small screens */
    }


    .my-text,
    .my-footer {
        max-width: 60%;
        font-size: 4vw; /* Increase font size for mobile */
    }
}
